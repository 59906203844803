body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*Menu*/

.navigation.navbar {
  float: right;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navigation.navbar-dark .navbar-nav .nav-link {
  padding: 0 25px;
  color: #fff;
  font-size: 24px;
  line-height: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
  margin: 2px;
}

.navigation.navbar-dark .navbar-nav .nav-link:focus,
.navigation.navbar-dark .navbar-nav .nav-link:hover {
  color: #FAFAFA;
  text-decoration: underline;
  border-style: hidden hidden solid hidden;
}

.navigation.navbar-dark .navbar-nav .active>.nav-link,
.navigation.navbar-dark .navbar-nav .nav-link.active,
.navigation.navbar-dark .navbar-nav .nav-link.show,
.navigation.navbar-dark .navbar-nav .show>.nav-link {
  color: #FAFAFA;
  text-decoration: underline;
  border-style: hidden hidden solid hidden;
}

.header {
  background: #050935;
  width: 100%;
  padding: 45px 0px 25px 0px;
}

.logo a {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
}

.banner_main {
  background: #050935;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 90px;
}

.text-bg h1 {
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  padding-bottom: 25px;
  font-weight: bold;
}

.text-bg span {
  color: #FAFAFA;
  font-size: 40px;
  line-height: 35px;
  font-weight: bold;
}

.text-bg p {
  color: #fff;
  font-size: 17px;
  line-height: 28px;
  padding: 40px 0;
}

.text-bg a {
  font-size: 16px;
  background-color: #fff;
  color: #000;
  padding: 10px 0px;
  width: 100%;
  max-width: 190px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.text-bg a:hover {
  background-color: #000;
  color: #fff;
}

.text-img figure {
  margin: 0px;
}

.text-img figure img {
  width: 100%;
}

.titlepage {
  text-align: center;
  padding-bottom: 60px;
}

.titlepage h2 {
  font-size: 45px;
  color: #050935;
  line-height: 40px;
  font-weight: bold;
  padding: 0;
}

/*Sobre Nosotros*/

.about {
  padding-top: 50px;
}

.web_about {
  text-align: center;
}

.web_about figure {
  margin: 0;
}

.web_about p {
  color: #050935;
  font-size: 17px;
  line-height: 28px;
  padding: 40px 1px;
}

.web_about a {
  font-size: 16px;
  background-color: #000;
  color: #fff;
  padding: 10px 0px;
  width: 100%;
  max-width: 190px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 10px;
}

.web_about a:hover {
  background-color: #050935;
  color: #fff;
}

.mw-300{
  max-width: 300px
}

/*Servicios*/

.Services {
  margin-top: 30px;
  padding: 30px 0;
  background: #050935;
  font-family: Poppins;
}

.Services .titlepage {
  text-align: center;
}

.Services .titlepage h2 {
  color: #fff;
  padding-bottom: 20px;
}

.Services .titlepage p {
  color: #fff;
}

.Services .titlepage Text {
  color: #fff;
}

.Services .Services-box img {
  background: #fff;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.Services .Services-box i {
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: inline-block;
  padding-top: 23px;
}

.Services .Services-box i:hover {
  background: #ddd;
}

.Services .Services-box h3 {
  padding: 20px 0px 20px 0px;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  display: block;
  font-weight: 500;
}

.Services .Services-box {
  text-align: center;
  background-color: transparent;
  border-radius: 20px;
  border: #cecfd7 solid 2px;
  padding: 40px;
  transition: ease-in all 0.5s;
  margin-bottom: 30px;
  height: 90%;
}

.Services .Services-box:hover {
  background: #8308eb;
  transition: ease-in all 0.5s;
  cursor: pointer;
}

.Services .Services-box p {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.read_more {
  font-size: 16px;
  background-color: #fff;
  color: #000;
  padding: 10px 0px;
  width: 100%;
  max-width: 190px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.read_more:hover {
  background: #8308eb;
  color: #fff;
}

/*Contacto*/

.contact {
  padding-top: 90px;
  font-family: Poppins;
}

.main_form {
  background: #0c0f38;
  padding: 55px 55px;
  border-radius: 30px;
  margin-bottom: 0%;
}

.contact .main_form .contactus {
  border: #fff solid 1px;
  padding: 0px 19px;
  margin-bottom: 20px;
  border-radius: 15px;
  width: 100%;
  height: 52px;
  background: #fff;
  color: #999999;
  font-size: 16px;
}

.contact .main_form .textarea {
  margin-bottom: 25px;
  padding: 0px 19px;
  color: #999999 !important;
  width: 100%;
  border: #fff solid 1px;
  border-radius: 15px;
  padding-top: 38px;
  background: #fff;
}

.contact .main_form .select {
  margin-bottom: 25px;
  padding: 0px 19px;
  color: #999999 !important;
  width: 100%;
  border: #fff solid 1px;
  border-radius: 15px;
  padding-top: 38px;
  background: #fff;
}

.contact .main_form .send {
  font-size: 16px;
  transition: ease-in all 0.5s;
  background-color: #fff;
  color: #ef4259;
  padding: 12px 70px;
  margin: 0 auto;
  display: block;
  border-radius: 30px;
}

.contact .main_form .send:hover {
  background-color: #8308eb;
  transition: ease-in all 0.5s;
  color: #fff;
}

.contact .main_form .get {
  font-size: 16px;
  transition: ease-in all 0.5s;
  background-color: #fff;
  color: #ef4259;
  padding: 12px 20px;
  margin: 0 auto;
  display: block;
  border-radius: 30px;
  width: 100%;
}

.contact .main_form .get:hover {
  background-color: #8308eb;
  transition: ease-in all 0.5s;
  color: #fff;
}

#contact *::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

#contact *::placeholder {
  color: #999999;
  opacity: 1;
}

.current {
  color: #fff;
  margin-top: -4px;
  position: absolute;
}

/*Rigths*/

.footer {
  font-family: Poppins;
  background: #0c0f38;
}

.cont {
  background: #0c0f38;
  text-align: center;
  margin-top: 31%;
  margin-left: 0%;
}

.cont h3 {
  color: #fff;
  font-size: 45px;
  line-height: 30px;
  font-weight: bold;
  padding: 30px 0px 0px 0px;
}

.cont span {
  color: #fff;
  font-size: 30px;
  line-height: 30px;
  padding: 30px 0px 30px 0px;
  display: inline-block;
  font-weight: bold;
}

.cont p {
  color: #fff;
  font-size: 17px;
  line-height: 30px;
  padding: 0 130px;
  padding-bottom: 60px;
}

.copyright {
  padding: 20px 0px;
  background: #fff;
}

.copyright p {
  color: #1e1e1e;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.copyright a {
  color: #1e1e1e;
}

.copyright a:hover {
  color: #0c0f38;
}

/*Derecha*/

.DerechaV {
  margin-top: 30px;
  padding: 30px 15px;
  background: #050935;
  font-family: Poppins;
}

.DerechaV h3 {
  padding: 20px 0px 20px 0px;
  font-size: 40px;
  line-height: 25px;
  color: #fff;
  display: block;
  font-weight: 500;
}

.DerechaV p {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

/*Izquierda*/

.IzquierdaV {
  margin-top: 30px;
  padding: 30px 15px;
  background: #fff;
  font-family: Poppins;
}

.IzquierdaV h3 {
  padding: 20px 0px 20px 0px;
  font-size: 40px;
  line-height: 25px;
  color: #050935;
  display: block;
  font-weight: 500;
}

.IzquierdaV p {
  font-size: 18px;
  line-height: 24px;
  color: #050935;
}

/*Solicitar*/

.Solicitar {
  text-align: center;
  margin-top: 30px;
  padding: 60px 15px;
  background: #050935;
  font-family: Poppins;
  display: flex;
}

.Solicitar h3 {
  padding: 20px 0px 60px 0px;
  font-size: 80px;
  line-height: 70px;
  color: #fff;
  display: block;
  font-weight: 500;
}

.Solicitar .Soli {
  font-size: 80px;
  transition: ease-in all 0.5s;
  background-color: #fff;
  color: #050935;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: min(120px,10%);
  padding-left: min(120px,10%);
  margin: 0 auto;
  display: block;
  border-radius: 30px;
}
